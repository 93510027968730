export default {
    index: {
        more: "更多",
        homeNews: "国内新闻",
        Industrial: '产业研究',
        business: "主营业务",
        hotspots: '主题热点',
        announcement: '预售公告',
        express: "业务直通车",
        company: '公司',
        money: '元',
        version: '版本号',
    },
    detailPage: {
        releaseTime: "发布时间",
        source: "来源",
        none: "无",
        productInfo: "产品信息",
        contractProductCode: "合约商品代码",
        contractProductName: "合约商品名称",
        specification: "规格",
        price: "价格",
        totalAmount: "总量",
        subscriptionDepositRatio: "认购方缴纳 保证金比例",
        breachLiability: "违约责任",
        preSaleTime: "预售时间",
        deliveryDate: "交货日期",
        transactionInfo: "交易信息",
        contractFeeType: "订立手续费类型",
        transferFeeType: "转让手续费类型",
        depositType: "保证金类型",
        contractServiceFeeAmount: "订立服务费金额",
        transferServiceFeeAmount: "转让服务费金额",
        depositAmount: "定金金额",
    },
    madinBusiness: {
        researchMarket: "苦心孤诣，深研市场",
        promoteFlows: "推动商流、物流、资金流和信息流的聚集，带动包括金融保险、信息咨询、商务服务、物流仓储、流通加工等石油产业链的发展。",
        serviceEntities: "为各级地方监管部门精准施策、金融机构服务实体提供数据支撑",
        platformEffectiveness: "平台成效",
        platformAdvantages: "平台优势",
        flexiblePricing: "石化购销线上化，灵活定价，高效交收",
        gatherQualityResources: "汇聚国内优质石油化工源，满足差异化石化购销需求。",
        diverseTradingModes: "提供多样化的现货交易模式，多场景产品形态，充分满足上下游客户不同类型需求",
        fullServiceSupport: "提供信息咨询、现货交易、仓储物流、供应链金融、售后处理等全流程、全链条配套服务。"
    },
    header: {
        download: "下载中心",
        index: "首页",
        address: '下载地址',
    },
    newsList: {
        laws: '法律法规',
        nodata: '暂无数据',
    },
    footer: {
        contactUs: '联系我们',
        address: '地址',
        phone: '电话',
        mail: '邮箱',
        index: "首页",
        download: "下载中心",
        car: "业务直通车",
    },
};
