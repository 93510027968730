import request from '../request.js'
import {
    url
} from '../request.js'

//获取栏位对应轮播图
export const getSwiper = (params = {}) => {
    return request({
        url: '/public/imgInfo',
        method: 'get',
        params: params
    })
}
//获取树形栏位数据
export const getColumnTree = (params = {}) => {
    return request({
        url: '/public/columnTree',
        method: 'post',
        data: params
    })
}
//获取栏位对应文章
export const getarticlesInfo = (params = {}) => {
    return request({
        url: '/public/articlesInfo',
        method: 'get',
        params: params
    })
}
//获取栏位对应文章
export const getarticleDetail = (params = {}) => {
    return request({
        url: '/public/article',
        method: 'get',
        params: params
    })
}

//获取树形栏位数据
export const getDownload = (params = {}) => {
    return request({
        url: '/public/downloadCenter',
        method: 'post',
        data: params
    })
}
//获取图标
export const getLogo = (params = {}) => {
    return request({
        url: '/public/getWebLogo',
        method: 'post',
        data: params
    })
}
//获取App图标
export const getAppLogo = (params = {}) => {
    return request({
        url: '/public/getAppLogo',
        method: 'post',
        data: params
    })
}

//获取客服电话
export const getServiceNumber = (params = {}) => {
    return request({
        url: '/public/getServiceNumber',
        method: 'post',
        data: params
    })
}

//获取类型对应轮播图
export const imgInfoByType = (params = {}) => {
    return request({
        url: '/public/imgInfoByType',
        method: 'get',
        params: params
    })
}
//获取热点文章和推荐阅读
export const hotArticles = (params = {}) => {
    return request({
        url: '/public/hotArticles',
        method: 'post',
        data: params
    })
}

//获取网站信息
export const getWebInfo = (params = {}) => {
    return request({
        url: '/public/getWebInfo',
        method: 'post',
        data: params
    })
}
//获取最新价
export const getPrice = (params = {}) => {
    return request({
        url: '/public/getPrice',
        method: 'post',
        data: params
    })
}

//获取预售商品列表
export const getPreSellList = (params = {}) => {
    return request({
        url: '/public/preSellList',
        method: 'get',
        data: params
    })
}

//获取预售商品详情

export const getPreSellDetail = (params = {}) => {
    return request({
        url: '/public/preSellDetail',
        method: 'get',
        params: params
    })
}
