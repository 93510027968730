import Vue from 'vue'
import VueRouter from 'vue-router'

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'index',
        component: () => import('../views/index/index'),
        meta: {},
    },
    {
        path: '/secondPage',
        name: 'SecondPage',
        component: () => import('../views/secondPage/secondPage'),
        meta: {},
    },
    {
        path: '/detailPage',
        name: 'DetailPage',
        component: () => import('../views/detailPage/detailPage'),
        meta: {},
    },
    {
        path: '/downloadPage',
        name: 'DownloadPage',
        component: () => import('../views/downloadPage/downloadPage'),
        meta: {},
    },
]



const router = new VueRouter({
    routes,
    mode: 'history',
    base: '/',
})

export default router