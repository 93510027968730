import { render, staticRenderFns } from "./asiderIcon.vue?vue&type=template&id=3da9bfa0&scoped=true&"
import script from "./asiderIcon.vue?vue&type=script&lang=js&"
export * from "./asiderIcon.vue?vue&type=script&lang=js&"
import style0 from "./asiderIcon.vue?vue&type=style&index=0&id=3da9bfa0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3da9bfa0",
  null
  
)

export default component.exports