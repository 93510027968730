import { render, staticRenderFns } from "./CenterTab.vue?vue&type=template&id=fed64898&scoped=true&"
import script from "./CenterTab.vue?vue&type=script&lang=js&"
export * from "./CenterTab.vue?vue&type=script&lang=js&"
import style0 from "./CenterTab.vue?vue&type=style&index=0&id=fed64898&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fed64898",
  null
  
)

export default component.exports