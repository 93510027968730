<template>
    <div>
        <div v-if="!$common.mobile()">
            <el-carousel indicator-position="inside" arrow="never" class="swiperBox"
                         v-if="swiperList&&swiperList.length>0">
                <el-carousel-item v-for="(item,index) in swiperList" :key="index">
                    <div @click="goPage(item)">
                        <img class="imgsty" :src="item.image" alt="">
                    </div>
                </el-carousel-item>
            </el-carousel>
            <img v-else class="imgBag" src="@/assets/img/bag.png" alt="">
            <div class="fl_r ju_c al_c" style="z-index: 999;position: relative">
                <div>
                    <img class="indexImg" :src="icon" alt="">
                </div>
                <div class="tabSty fl_r ju_c">
                    <div class="tabItem" @mouseenter="mouseenter(item)" @mouseleave="mouseleave(item)"
                         v-for="(item,index) in headerList" :key="index" @click="goPage(item)"
                         :style="{'font-weight': (item.showSecondTab ? 'bold':'')}">
                        <div class="fl_c al_c heig90">
                            {{item.name}}
                            <div v-if="item.showSecondTab" class="formalTab"></div>
                        </div>

                        <div v-if="item.showSecondTab&&item.subArticles.length>0&&item.columnType!='01'"
                             class="pos_a fl_r ju_c childSty">
                            <div v-for="(childItem,childIndex) in item.subArticles" :key="childIndex"
                                 @click.stop="goChildPage(item,childItem)"
                                 @mouseenter="mouseenter1(childItem)" @mouseleave="mouseleave1(childItem)"
                                 class="childRow"
                                 :style="{'background-color': (childItem.selected ? 'rgba(251, 37, 55, 0.3)':'#fff')}">
                                <div>{{childItem.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fl_r ju_b header_height_box1" v-if="$common.mobile()">
            <img :src="icon" alt="" class="cropped-image" mode="aspectFit">
            <img src="../../assets/img/bg-list.png" alt="" @click="isMore=!isMore"
                 style="width: 40px;height: 40px;margin: 5px 20px 5px 5px">
        </div>
        <div class="select_box animate__animated animate__slideInDown" v-if="isMore" @click="isMore=false">
            <div
                    :class="['select_item', {'select_item_active' : current == item.id}]"
                    v-for="item in headerList" @click="goPage(item)"
                    :key="item.id">{{item.name}}
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getSwiper,
        getColumnTree,
        getLogo,
        imgInfoByType,
        getServiceNumber,
        getAppLogo
    } from '../../utils/api/index.js'
    import CenterTab from "../CenterTab/CenterTab";

    export default {
        name: "Header",
        components: {CenterTab},
        data() {
            return {
                isMore: false,
                current: 1,
                formalItem: [],
                headerList: [],
                columnImg: [],
                title: '',
                icon: '',
                inputValue: '',
                showSecondTab: false,
                phone: null,
                EWMImg: null,
                swiperList: [],
            }
        },
        computed: {
            formalItem1: {
                get() {
                    return this.$store.state.formalItem;
                },
            },
        },
        watch: {
            formalItem1: {
                handler: function (newValue, oldValue) {
                    this.getSwiper(newValue.id)
                },
                immediate: true,
            },
        },
        mounted() {
            this.getColumnTree()
            this.imgInfoByType()
            this.getServiceNumber()
            this.getLogo()
            this.getAppLogo()
        },
        methods: {
            async getServiceNumber() {
                let res = await getServiceNumber()
                this.phone = res.data
            },
            async imgInfoByType() {
                let res = await imgInfoByType({
                    belongType: 91
                })
                this.EWMImg = res.data[0]
            },
            goPage(data) {
                // if (data.name == this.$t('header.download')) {
                //     this.swiperList = []
                //     return this.$router.push('downloadPage')
                // }
                // if (data.name == this.$t('header.index')) {
                //     this.$router.push('/')
                // }
                if (data.name == '下载中心'||data.name == 'Download') {
                    this.swiperList = []
                    return this.$router.push('downloadPage')
                }
                if (data.name == '首页'||data.name == 'home page') {
                    this.$router.push('/')
                } else {
                    if(data.subArticles&&data.subArticles.length>0){
                        this.$router.push({path: '/secondPage', query: {columnId: data.subArticles[0].id}})
                    }
                }
                this.$store.commit('setFormalItem', data);
                this.$store.commit('setChildItem', data.subArticles[0]);
            },
            goChildPage(data, child) {
                // if (child.name == this.$t('header.address')) {
                //     return this.$router.push('downloadPage')
                // }
                if (child.name == '下载地址'||data.name == '下载中心'||data.name == 'Download') {
                    return this.$router.push('downloadPage')
                }
                this.$store.commit('setFormalItem', data);
                this.$store.commit('setChildItem', child);
                this.$router.push({path: '/secondPage', query: {columnId: child.id}})
            },
            mouseenter1(data) {
                this.$set(data, 'selected', true)
            },
            mouseleave1(data) {
                this.$set(data, 'selected', false)
            },
            mouseenter(data) {
                this.$set(data, 'showSecondTab', true)
                this.showSecondTab = true
            },
            mouseleave(data) {
                // if (this.$store.state.formalItem.columnType != data.columnType) {
                // }
                this.$set(data, 'showSecondTab', false)
                this.showSecondTab = false
            },
            async getLogo() {
                let res = await getLogo()
                this.icon = res.data.image
                this.title = res.data.title.split('/')
                document.title = this.title[0]

            },
            async getAppLogo() {
                let res = await getAppLogo()
                const link = document.querySelector("link[rel~='icon']") || document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'icon';
                link.href = res.data.image;
                document.head.appendChild(link);
            },
            async getSwiper(id) {
                let res = await getSwiper({
                    columnId: id
                })
                this.swiperList = res.data
            },
            async getColumnTree() {
                let res = await getColumnTree();
                res.data = res.data.filter(item => {
                    if (item.columnType === '03') {
                        return false;
                    } else {
                        if (item.columnType !== '01') {
                            item.subArticles.forEach(item1 => {
                                item1.selected = false;
                            });
                        }

                        if (this.$store.state.formalItem.columnType && this.$store.state.formalItem.columnType === item.columnType) {
                            item.showSecondTab = true;
                        } else {
                            item.showSecondTab = false;
                        }

                        if (item.subArticles && item.subArticles.length > 0) {
                            //  item.subArticles
                        }

                        return true;
                    }
                });


                this.headerList = res.data
            },
        },
    }
</script>

<style scoped lang="scss">
    .indexImg {
        width: 100%;
        height: 50px;
        margin-left: 120px;

    }

    .buttonSty {
        background-color: #B70C04;
    }


    ::v-deep .el-input-group__append {
        background-color: #B70C04;
        border-radius: 0 5px 5px 0;
        width: 96px;
        text-align: center;
    }

    ::v-deep .el-icon-search:before {
        color: white;
    }

    .codeImg {
        width: 85px;
        height: 80px;

        .imgBox {
            width: 58px;
            height: 58px;
        }

        .imgBox:hover {
            width: 250px;
            height: 250px;
            z-index: 9;
        }

        .codeBottom {
            width: 85px;
            height: 18px;
            border-radius: 10px;
            background-color: #03920a;
            text-align: center;
            color: white;
            font-size: 10px;
            font-weight: bold;
            margin-top: 10px;
        }
    }

    .tabSty {
        width: 100%;
        height: 90px;

        .heig90{
            height: 90px;
            white-space: nowrap;
        }
        .tabItem {
            width: 10%;
            height: 100%;
            line-height: 90px;
            color: white;
            text-align: center;
            cursor: pointer;
            font-size: 20px;

            .formalTab {
                width: 66px;
                height: 4px;
                background: #fff;
            }

            .childSty {
                position: absolute;
                left: 0;
                width: 100%;
                height: 130px;


                z-index: 999;

                .childRow {
                    font-size: 22px;
                    color: black;
                    font-weight: bold;
                    margin: 15px 0px;
                    padding: 0 50px;
                    line-height: 100px;
                }
            }
        }
    }

    .header_height_box1 {
        width: 100%;
        display: flex;
        z-index: 9;
        position: fixed;
        top: 0;
        height: 50px;
        background-color: white;
    }

    .select_box {
        left: 0px;
        top: 50px;
        width: 100%;
        background: #fff;
        z-index: 999;
        position: fixed;
    }

    .select_item {
        padding: 10px;
        font-size: 12px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 700;
        color: #333333;
        box-sizing: border-box;
    }

    .cropped-image {
        width: calc(100% - 60px);
        height: 100%;
        /*margin: 5px 0px 5px 20px*/
    }

    .imgBag {
        flex: 1;
        width: 100%;
        height: 532px;
        position: absolute;
        top: 0;
        z-index: 0;
    }

    .swiperBox {
        width: 100%;
        height: 532px;
        position: absolute;
        top: 0;
        z-index: 0;

        ::v-deep .el-carousel__container {
            height: 532px;
        }
    }

</style>
