import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import VueI18n from 'vue-i18n';
import zh from './locales/zh';
import en from './locales/en';

import 'element-ui/lib/theme-chalk/index.css';
import router from "./router";
import store from "./store";
import common from './utils/common'

Vue.use(VueI18n);
// 定义语言环境
const messages = {
    'zh-CN': zh,
    'zh-TW': 'zn',
    'en-US': en,
    'th-TH': 'zn',
};
const i18n = new VueI18n({
    locale: store.state.language ? store.state.language : 'zh-CN',
    messages,
});

Vue.prototype.$common = common
Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
    i18n,
    render: h => h(App),
    router,
    store
}).$mount('#app')
