export default {
    index: {
        more: "MORE",
        homeNews: "Home news",
        Industrial: "Industrial research",
        business: "Main business",
        hotspots: 'Theme hotspots',
        announcement: 'Pre sale announcement',
        express: "Business Express",
        company: 'Company',
        money: 'yuan',
        version: 'Version number',
    },
    detailPage: {
        releaseTime: "Release time",
        source: "Source",
        none: "None",
        productInfo: "Product information",
        contractProductCode: "Contract product code",
        contractProductName: "Contract product name",
        specification: "Specification",
        price: "Price",
        totalAmount: "Total amount",
        subscriptionDepositRatio: "Subscription deposit ratio",
        breachLiability: "Breach liability",
        preSaleTime: "Pre sale time",
        deliveryDate: "Delivery date",
        transactionInfo: "Transaction information",
        contractFeeType: "Contract fee type",
        transferFeeType: "Transfer fee type",
        depositType: "Deposit type",
        contractServiceFeeAmount: "Contract service fee amount",
        transferServiceFeeAmount: "Transfer service fee amount",
        depositAmount: "Deposit amount"
    },
    madinBusiness: {
        researchMarket: "Research the market with great effort",
        promoteFlows: "Promote the aggregation of business flow, logistics, capital flow, and information flow, driving the development of the petroleum industry chain, including financial insurance, information consulting, business services, logistics warehousing, and circulation processing.",
        serviceEntities: "Provide data support for precise policy implementation by local regulatory authorities and financial institutions serving entities.",
        platformEffectiveness: "Platform effectiveness",
        platformAdvantages: "Platform advantages",
        flexiblePricing: "Online petrochemical trading, flexible pricing, and efficient delivery.",
        gatherQualityResources: "Gather domestic high-quality petrochemical sources to meet differentiated petrochemical purchasing and sales needs.",
        diverseTradingModes: "Provide a variety of spot trading modes and multi-scenario product forms to fully meet the different needs of upstream and downstream customers.",
        fullServiceSupport: "Provide full-process and full-chain supporting services such as information consulting, spot trading, warehousing and logistics, supply chain finance, and after-sales processing."
    },
    header: {
        download: "Download Center",
        index: "Index",
        address: 'Download address',
    },
    newsList: {
        laws: 'Laws and Regulations',
        nodata: 'There is currently no data available',
    },
    footer: {
        contactUs: 'Contact Us',
        address: 'Address',
        phone: 'Phone',
        mail: 'Email',
        index: "Index",
        download: "Download Center",
        car: "Business Express",
    },
};
