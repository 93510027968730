<template>
    <div
            class="foot-box"
            :style="$common.mobile() ? 'height: 150px;padding: 10px 0;' : ''"
    >
        <div class="text-list" v-if="!$common.mobile()">
            <div
                    class="list-item"
                    v-for="(item, index) in footList"
                    :key="index"
                    :style="index == footList.length - 1 ? 'margin-right: 50px ;' : ''"
            >
                <dl>
                    <dt class="dt-left">{{ item.name }}</dt>
                    <dd
                            class="dd-rt"
                            v-for="sub in item.subArticles"
                            @click="chooseOne(sub, item)"
                            :key="sub.id"
                    >
                        {{ sub.name }}
                    </dd>
                </dl>
            </div>
            <div class="list-item">
                <dl>
                    <dt class="dt-left">{{ $t('footer.contactUs') }}</dt>
                    <dd class="dd-rt img-rt">
                        <img src="../../assets/scPage/address.png" alt="" class="icon"/>
                        <div>
                            {{ $t('footer.address') }}:<span>{{ webInfo.contractAddress }}</span>
                        </div>
                    </dd>
                    <dd class="dd-rt img-rt">
                        <img src="../../assets/scPage/ph.png" alt="" class="icon"/>
                        <div>
                            {{ $t('footer.phone') }}:<span>{{ phone }}</span>
                        </div>
                    </dd>
                    <dd class="dd-rt img-rt">
                        <img src="../../assets/scPage/email.png" alt="" class="icon"/>
                        <div>
                            {{ $t('footer.mail') }}:<span>{{ webInfo.emailFeedback }}</span>
                        </div>
                    </dd>
                </dl>
            </div>
            <div class="codeImg-box">
                <div class="item-img" v-for="item in EWMImg" :key="item.id">
                    <img v-if="EWMImg != null" :src="item.image" alt="" class="code"/>
                    <p>{{ item.title }}</p>
                </div>
            </div>
        </div>
        <div class="fl_r ju_b al_c " style="width: 80%;margin: 0 auto;">
            <div class="grayText grayflex" v-if="!$common.mobile()">
                <div class="le-text">{{webInfo.copyright}}</div>
                <div class="ri-text" v-if="webInfo.filingInformation&&webInfo.filingInformation.length>0">
                    <span @click="goPage">{{webInfo.filingInformation[0]}}&nbsp;&nbsp;</span>
                    <img src="@/assets/img/ghs.png" alt="" style="vertical-align: middle"/>
                    <span @click="goPage1"
                    >&nbsp;&nbsp;{{webInfo.filingInformation[1]}}</span
                    >
                </div>
            </div>
            <div class="fl_r language">
                <div @click="changeLanguage('zh-CN')">中文</div>
                <span>/</span>
                <div @click="changeLanguage('en-US')">English</div>
            </div>
        </div>
        <!-- .............................................. -->
        <div class="grayText grayCet" v-if="$common.mobile()">
            <div class="ri-text fl_r fr-ce" v-if="webInfo.filingInformation&&webInfo.filingInformation.length>0">
                <p @click="goPage">{{webInfo.filingInformation[0]}}</p>
                <p @click="goPage1">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{webInfo.filingInformation[1]}}
                </p>
            </div>
            <div class="le-text" style="margin-top: 20px">
                {{webInfo.copyright}}
                <!-- <p>Copyright 2020-2024 AII Right Reserved</p> -->
            </div>
        </div>
    </div>
</template>

<script>
    import {
        imgInfoByType,
        getWebInfo,
        getColumnTree,
        getServiceNumber,
    } from "../../utils/api/index.js";

    export default {
        data() {
            return {
                EWMImg: [],
                footList: [],
                webInfo: {},
                phone: null,
            };
        },
        mounted() {
            this.imgInfoByType();
            this.getWebInfo();
            this.getColumnTree();
            this.getNumber();
        },
        methods: {
            changeLanguage(lang) {
                this.$i18n.locale = lang;
                this.$store.commit('setLanguage', lang);
                window.location.reload();
            },
            goPage() {
                window.open("https://beian.miit.gov.cn", "_blank");
            },
            goPage1() {
                window.open(
                    "https://www.beian.gov.cn/portal/registerSystemInfo",
                    "_blank"
                );
            },
            async imgInfoByType() {
                let res = await imgInfoByType({
                    belongType: 91,
                });
                this.EWMImg = res.data;
            },
            async getWebInfo() {
                let res = await getWebInfo();
                if (res.data.filingInformation) {
                    res.data.filingInformation = res.data.filingInformation.split("   ")
                }
                this.webInfo = res.data;
            },
            async getNumber() {
                let res = await getServiceNumber();
                this.phone = res.data;
            },
            async getColumnTree() {
                let res = await getColumnTree();
                this.footList = res.data.filter((item) => {
                    // if (item.name != this.$t('footer.index') && item.name != this.$t('footer.download') && item.name != this.$t('footer.car')) {
                    //     return item;
                    // }
                    if (item.name != "首页" && item.name != "下载中心" && item.name != "业务直通车"
                        && item.name != "home page"  && item.name != "Download") {
                        return item;
                    }
                });
            },
            chooseOne(sub, data) {
                this.$router.push({
                    path: "/secondPage",
                    query: {columnId: sub.id},
                });
                this.$store.commit("setFormalItem", data);
                this.$store.commit("setChildItem", sub);
            },
        },
    };
</script>

<style scoped lang="scss">
    .fl_r {
        display: flex;
        flex-direction: row;
    }

    .fr-ce {
        justify-content: center;
        align-items: center;
    }

    .foot-box {
        width: 100%;
        height: auto;
        background: #850416;
        padding: 55px 0 45px;
        box-sizing: border-box;

        .text-list {
            width: 80%;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            padding-bottom: 40px;
            box-sizing: border-box;
            border-bottom: 1px solid rgba(255, 255, 255, 0.26);

            .list-item {
                display: flex;
                color: #fff;
                font-family: "PingFang SC";

                .dt-left {
                    font-size: 20px;
                    margin-bottom: 20px;
                    font-weight: 500;
                    box-sizing: border-box;
                }

                .dd-rt {
                    font-size: 16px;
                    margin-bottom: 15px;
                    font-weight: 200;
                    box-sizing: border-box;
                }

                .img-rt {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .icon {
                        width: 15px;
                        height: 16px;
                        margin-right: 24px;
                        box-sizing: border-box;
                    }
                }
            }

            .codeImg-box {
                display: flex;

                .item-img {
                    width: 120px;
                    height: 130px;
                    color: #fff;
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-weight: 300;
                    text-align: center;

                    .code {
                        width: 108px;
                        height: 108px;
                    }
                }
            }
        }

        .grayText {
            margin: 10px 0;
            height: 20px;
            box-sizing: border-box;
            color: rgba(255, 255, 255, 0.5);
            font-family: "PingFang SC";
            font-size: 16px;
            font-weight: 400;
        }

        .grayflex {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .grayCet {
            text-align: center;
        }
    }

    .language {
        color: white;

        div {
            cursor: pointer;
        }

        span {
            margin: 0 10px;
        }
    }
</style>
