<template>
  <div class="contain">
    <div class="asd-icon" v-if="!isShowEWM">
      <div class="img-box" @click="goHome">
        <img src="../../assets/scPage/home.png" alt="" class="i-img" />
      </div>
      <div class="img-box" @click="isShowEWM = !isShowEWM">
        <img src="../../assets/scPage/card.png" alt="" class="i-img" />
      </div>
      <div class="img-box top" @click="backToTop">
        <img src="../../assets/scPage/go.png" alt="" class="i-img" />
      </div>
    </div>
    <div
      class="box-EWM"
      :style="isShowEWM && EWMImg.length > 1 ? 'top: 40vh;' : ''"
      v-if="isShowEWM"
    >
      <i class="el-icon-close" @click="isShowEWM = !isShowEWM"></i>
      <div class="item-img-box" v-for="item in EWMImg" :key="item.id">
        <img v-if="EWMImg != null" :src="item.image" alt="" class="code" />
        <p>{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { imgInfoByType } from "../../utils/api/index.js";
export default {
  name: "asiderIcon",
  data() {
    return {
      EWMImg: [],
      isShowEWM: false,
    };
  },
  mounted() {
    this.imgInfoByType();
  },
  methods: {
    backToTop() {
      window.scrollTo(0, 0);
    },
    goHome() {
      this.$router.push("/");
    },
    async imgInfoByType() {
      let res = await imgInfoByType({
        belongType: 91,
      });
      this.EWMImg = res.data;
    },
  },
};
</script>

<style scoped lang="scss">
.contain {
  position: relative;
  z-index: 9999999999;
  .asd-icon {
    position: fixed;
    right: 0px;
    top: 50vh;
    float: right;
    width: 54px;
    height: 300px;
    .img-box {
      width: 54px;
      height: 54px;
      background-color: #fff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
      display: flex;
      justify-content: center;
      align-items: center;
      .i-img {
        width: 30px;
        height: 30px;
      }
    }
    .top {
      margin-top: 30px;
    }
  }
  .box-EWM {
    position: fixed;
    right: 0px;
    top: 50vh;
    // width: 250px;
    height: auto;
    background: #850416;
    padding: 10px 25px 10px 10px;
    box-sizing: border-box;
    .item-img-box {
      width: 120px;
      height: 130px;
      color: #fff;
      font-family: "PingFang SC";
      font-size: 14px;
      font-weight: 300;
      text-align: center;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
      .code {
        width: 108px;
        height: 108px;
      }
    }
    .el-icon-close {
      position: absolute;
      top: 2px;
      right: 2px;
      color: #fff;
      font-size: 22px;
    }
  }
}
</style>
